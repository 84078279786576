import React from 'react';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';

const ThankYou = () => {
  return (
    <Layout footer={false}>
      <Seo title="Bridge Patient Portal" description="Thank you" />
      <Section variant="hero">
        <h1 className="text-center">Thank You</h1>
        <p className="text-secondary text-center fw-bold">
          Thank you for contacting Bridge Patient Portal!
        </p>
        <p className="text-secondary text-center fw-bold">
          Your message has been received, and we’ll be in touch shortly.
        </p>
      </Section>
    </Layout>
  );
};

export default ThankYou;
